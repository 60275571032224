@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600;700&display=swap');

.App {
    
}

/* atomic classes */
.flex {
    display: flex;
}

.flex-1 {
    flex: 1;
}