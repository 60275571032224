.app-loader {
    background-color: #F7F8FA;
    height: 100%;
    left: 0;;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 111;
}

.app-loader .app-loader__content {
    position: relative;
    top: calc(50% - 60px);
}

.app-loader .app-loader__message {
    color: #32393B;
    letter-spacing: normal;
    line-height: 1.38;
    font-family: Roboto;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 24px;
    text-align: center;
}

.app-loader .loader {
    margin-top: 0;
}