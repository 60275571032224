.stack-item {
    position: fixed;
    transition: bottom 300ms ease-out;
    z-index: 1000;
    left: 50%;
    transform: translateX(-50%);
}


.stack-item.stack-item--remove {
    transition: visibility 300ms ease-in;
}