/* @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700'); */
/* @import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
/* @import url('https://fonts.googleapis.com/css?family=Manrope:400,500,%20600,700,800'); */
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600;700&display=swap');

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    /* font-family: Helvetica; */
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input, select {
    font-family: 'Inter', sans-serif;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Inter', sans-serif;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'Inter', sans-serif;
}

input::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'Inter', sans-serif;
}

#root, .App {
    height: 100%;
}

.clearfix {
    clear: both;
}

.hide {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}

.content-align-right {
    text-align: right;
}

.push {
    margin-left: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
  transition: background-color 5000s ease-in-out 0s;
}

input {
    filter: none;
}

.tb-add-question-modal .ask-bar-header {
    display: none;
}