.profile-dropdown-container {
    // width: 228px;

    &.top-align {
        margin-top: 8px;
        position: absolute;
        right: 0px;
    }

    &.bottom-align {
        margin-top: 8px;
        position: absolute;
        right: 8px;
        bottom: calc(100% + 4px);
        box-sizing: border-box;
        width: calc(100% - 16px);
    }

    .common-action-menu {
        width: max-content;
    }

}

.mobile-profile{
    width: 32px;
    height: 32px;
}

.mobile-toaster{
    display: flex;
    bottom: 72px;
    display: flex;
    width: 100%;
    position: fixed;
    left: 0px;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}