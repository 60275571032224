.main-page-container {
  height: 100%;
}

.dropdown-link {
  text-align: center;
  color: #5d697a;
  a {
    text-decoration: none;
    color: inherit;
    vertical-align: unset;
  }

  .dot::before {
    display: inline-block;
    margin: 0 8px;
    content: "\2022";
    color: #5d697a;
    vertical-align: middle;
  }


}

.delete-conversation__modal {
  .modal-content {
    max-width: 468px;
  }
}
