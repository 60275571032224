.loader-div.modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000; 
}

.loader-div.modal .loader-content {
    width: 400px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #b2bec3;
    padding: 56px;
    margin: 25vh auto 0px;
}

.loader-div .loader {
    height: 8px;
    margin: auto;
    margin-top:120px;
    margin-bottom: 32px;
    width: 608px;
    position: relative;
    overflow: hidden;
    border-radius: 8.5px;
    background-color: #dfe6e9;
}

.loader-div .loader:before {
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 238px;
    height: 8px;
    border-radius: 8.5px;
    background-color: #304FFE;
    animation: loading 2s linear infinite;
}

.loader-div .loader-text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    color: #2d3436;
    margin-bottom: 60px;
}

.loader-div.modal .loader-text {
    margin-bottom: 0px;
}


/* Circular loader */
.loader-div .circular-loader {
    text-align: center;
    margin-bottom: 16px;
    height: 36px;
}

.loader-div .circular-loader img {
    width: 36px;
    height: 36px;
}

/* Tick spinner */
.tick-spinner {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
}

.tick-spinner div {
    transform-origin: 8px 8px;
    animation: tick-spinner 0.7s linear infinite;
}

.tick-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 0px;
    left: 7px;
    width: 2px;
    height: 5px;
    border-radius: 20%;
    background: #829ab1;
}

.tick-spinner div:nth-child(1) {
    transform: rotate(10deg);
    animation-delay: -0.6s;
}
.tick-spinner div:nth-child(2) {
    transform: rotate(61.42deg);
    animation-delay: -0.5s;
}
.tick-spinner div:nth-child(3) {
    transform: rotate(112.84deg);
    animation-delay: -0.4s;
}
.tick-spinner div:nth-child(4) {
    transform: rotate(164.26deg);
    animation-delay: -0.3s;
}
.tick-spinner div:nth-child(5) {
    transform: rotate(215.68deg);
    animation-delay: -0.2s;
}
.tick-spinner div:nth-child(6) {
    transform: rotate(267.1deg);
    animation-delay: -0.1s;
}
.tick-spinner div:nth-child(7) {
    transform: rotate(318.52deg);
    animation-delay: -0s;
}

.circular-mono-color {
    border: 1px solid transparent;
    border-radius: 50%;
    border-top: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    width: 14px;
    height: 14px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
}
  
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes tick-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes loading {
    from {left: -100px; width:0%;}
    to {left: 100%; width:80%;}
}